<template>
  <v-dialog v-model="dialog" max-width="1000" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Product DO</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="internal.products"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.unitPrice="{ item }">
                {{ formatPrice(item.unitPrice) }}
              </template>
              <template v-slot:item.total="{ item }">
                {{ formatPrice(item.total) }}
              </template>
              <template v-slot:body.append="{}">
                <tr>
                  <td colspan="6">TOTAL</td>
                  <td style="border: 1px">
                    {{ formatPrice(total) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headerInternal"
              :items="internal.productInternals"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="12" sm="12" md="9" lg="9">
                      <span class="headline">Product Internal</span>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close" class="mr-2">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-product-do",
  props: {
    dialog: Boolean,
    internal: Object,
  },
  data() {
    return {
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Product Detail",
          value: "partDesc",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Qty",
          value: "quantity",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "U/M",
          value: "unitMeasure",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "U/Price",
          value: "unitPrice",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Disc %",
          value: "discount",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Amount",
          value: "total",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Line Item",
          value: "lineItem",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Part#",
          value: "partNumber",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "PO#",
          value: "poNumber",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "DO#",
          value: "doNumber",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Mat Doc#",
          value: "materialDocumentNumber",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
      headerInternal: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Product Detail",
          value: "partDesc",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Qty",
          value: "qty",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "U/M",
          value: "um",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Part#",
          value: "partNumber",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    total() {
      let total = 0;
      if (this.internal.products) {
        this.internal.products.forEach(x => {
          total += parseFloat(x.total);
        });
      }
      return total;
    },
  },
};
</script>

<style></style>
