<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="saleDownPayment"
          :accounts="accounts"
          :contacts="contacts"
          :totalPercentageDownPayment="totalPercentageDownPayment"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail :id="id" :item="saleDownPayment" :contacts="contacts" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="printJournal">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Journal</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="routeToPrintPreview">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Print Preview</span>
            </v-tooltip>
          </v-col>
        </v-row> -->
        <v-row class="py-0 mb-2" v-if="edit && !saleDownPayment.fp">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="previewPajak">
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>
              </template>
              <span>Export Pajak</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row
          class="py-0 mb-2"
          v-if="edit && saleDownPayment.percentageUpTo === 100 && !saleDownPayment.linkDo"
        >
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="addDo">
                  <v-icon>
                    mdi-link
                  </v-icon>
                </v-btn>
              </template>
              <span>Link DO</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="openRemark">
                  <v-icon>
                    mdi-note
                  </v-icon>
                </v-btn>
              </template>
              <span>Remark</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && saleDownPayment.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- <v-row class="py-0 mb-2" v-if="edit && saleDownPayment.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-cancel
                  </v-icon>
                </v-btn>
              </template>
              <span>Cancel</span>
            </v-tooltip>
          </v-col>
        </v-row> -->
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-cancel
      :dialog="dialog"
      :invoiceNumber="saleDownPayment.invoiceNumber"
      @cancel="cancelInvoice('')"
      @cancelInvoice="cancelInvoice('invoice')"
      @close="close"
    ></dialog-cancel>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="closeReview"
      currency="IDR"
    ></dialog-review-journal>
    <dialog-link-do
      :dialog="dialogLinkDo"
      :deliveryOrders="deliveryOrders"
      @save="linkDo"
      @close="close"
    ></dialog-link-do>
    <dialog-remark
      :dialog="dialogRemark"
      :form="formRemark"
      @save="updateRemark"
      @close="close"
    ></dialog-remark>
    <preview-faktur-pajak
      :dialog="pajak"
      :item="saleDownPayment"
      ref="pajak"
      @close="close"
      @export="exportPajak"
      dp
    ></preview-faktur-pajak>
  </form>
</template>

<script>
import Detail from "./DetailSaleDownPayment";
import FormEdit from "./FormSaleDownPayment";
import DialogCancel from "@/components/DialogCancel";
import { mapState } from "vuex";
import fileDownload from "js-file-download";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import DialogLinkDo from "@/components/DialogLinkDo";
import DialogRemark from "@/components/DialogRemark";
import PreviewFakturPajak from "../PreviewFakturPajak.vue";

export default {
  name: "sale-down-payment-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-cancel": DialogCancel,
    DialogReviewJournal,
    DialogLinkDo,
    DialogRemark,
    PreviewFakturPajak,
  },
  props: {
    id: Number,
  },
  data: () => ({
    edit: true,
    dialog: false,
    pajak: false,
    dialogReview: false,
    dialogLinkDo: false,
    dialogRemark: false,
    accounts: [],
    journals: [],
    deliveryOrders: [],
    saleDownPayment: {},
    formRemark: {},
  }),
  computed: {
    ...mapState("external", ["contacts"]),
    ...mapState("saleDownPayment", ["totalPercentageDownPayment"]),
  },
  methods: {
    async getSaleDownPayment() {
      const response = await this.$store.dispatch("saleDownPayment/getById", this.id);
      if (response.status === 200) {
        this.saleDownPayment = response.data;
        response.data.purchaseOrders.forEach(
          async x => await this.$store.dispatch("saleDownPayment/totalDownPayment", x.id)
        );
        await this.$store.dispatch("external/getContact", response.data.customerId);
      }
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.checkJournal();
    },
    previewPajak() {
      this.pajak = true;
      this.$refs.pajak.createPdf();
    },
    async updateRemark(item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleDownPayment/updateRemark", item)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    openRemark() {
      this.dialogRemark = true;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialogLinkDo = false;
      this.dialogRemark = false;
      this.pajak = false;
    },
    closeReview() {
      this.dialogReview = false;
    },
    cancel() {
      this.getSaleDownPayment();
      this.edit = true;
    },
    routeToPrintPreview() {
      this.$router.push({
        name: "Print Preview Sale Down Payment",
        params: { id: this.id },
      });
    },
    printJournal() {
      this.$router.push({ name: "Journal Sale Down Payment", params: { id: this.id } });
    },
    async exportPajak() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/exportPajak", { id: this.id, type: "DP" })
        .then(response => {
          this.$store.commit("SET_LOADER", false);
          fileDownload(
            response.data,
            "ExportPajakInvoice-" + this.saleDownPayment.invoiceNumber + ".csv"
          );
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async cancelInvoice(type) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleDownPayment/cancelInvoice", { id: this.id, type: type })
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    addDo() {
      this.getDeliveryOrders();
      this.dialogLinkDo = true;
    },
    linkDo(items) {
      this.journals = [];
      this.items = items;
      let total = 0;
      let products = [];
      items.forEach(x => {
        x.products.forEach(y => {
          total += y.total;
          products.push(y);
        });
      });
      if (this.saleDownPayment.percentageUpTo === 100) {
        this.totalPercentageDownPayment.forEach(x => {
          this.journals.unshift({
            accountName: `UANG MUKA PENJUALAN ${this.saleDownPayment.currency}`,
            debit: total * (x.percentage / 100.0),
            credit: 0,
          });
        });
        let map = products.map(x => x.accountName);
        let array = map.filter((v, i, a) => a.indexOf(v) === i);
        array.forEach(y => {
          let total = 0;
          products.forEach(x => {
            if (x.accountName === y) {
              total += x.total;
            }
          });
          this.journals.push({
            accountName: `${y}`,
            debit: 0,
            credit: total,
          });
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    async submit() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleDownPayment/linkDoV2", { id: this.id, body: this.items })
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    getDeliveryOrders() {
      this.saleDownPayment.purchaseOrders.forEach(async x => {
        await this.$store
          .dispatch("external/getSaleDeliveryOrderForLinkDO", x.id)
          .then(response => {
            this.deliveryOrders = this.deliveryOrders.concat(response.data);
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      });
    },
    getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getSaleDownPayment(), this.getAccounts()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
  beforeMount() {
    this.formRemark = {
      remark: "",
      type: "Down Payment",
      transactionId: this.id,
    };
  },
};
</script>

<style></style>
