<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Link DO</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="8" sm="8" md="6" lg="6">
            <v-autocomplete
              outlined
              flat
              dense
              label="DO"
              background-color="white"
              append-icon="mdi-magnify"
              item-text="name"
              return-object
              v-model="deliveryOrder"
              :items="deliveryOrders"
              :filter="filterObjectDo"
              :rules="[v => !!v || 'DO is required']"
            >
              <template v-slot:item="data">
                <v-list-item v-on="data.on">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.ref_do"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" sm="4" md="2" lg="2">
            <v-btn color="success" @click="addDeliveryOrder" class="mr-2">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          sort-by="no"
          dense
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.action="{ item, index }">
            <v-btn color="error" icon @click.stop="deleteItem(index)" class="mr-2">
              <v-icon>
                mdi-trash-can
              </v-icon>
            </v-btn>
            <v-btn color="primary" icon @click.stop="viewProduct(item.id)">
              <v-icon>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
        <v-btn color="error" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <dialog-product-do
      :dialog="dialogProduct"
      :internal="internal"
      @close="closeProduct"
    ></dialog-product-do>
  </v-dialog>
</template>

<script>
import DialogProductDO from "./DialogProductDO.vue";
export default {
  name: "dialog-link-do",
  props: {
    dialog: Boolean,
    deliveryOrders: Array,
  },
  components: {
    "dialog-product-do": DialogProductDO,
  },
  computed: {
    total() {
      let total = 0;
      this.items.forEach(x => {
        x.products.forEach(y => {
          total += y.total;
        });
      });
      return total;
    },
  },
  data() {
    return {
      internal: {},
      items: [],
      deliveryOrder: null,
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "DO Number",
          value: "name",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
      dialogProduct: false,
    };
  },
  methods: {
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
      }
    },
    save() {
      this.$emit("save", this.items);
    },
    close() {
      this.deliveryOrder = null;
      this.items = [];
      this.$emit("close");
    },
    closeProduct() {
      this.dialogProduct = false;
    },
    async viewProduct(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("external/getSaleDeliveryOrdersDetailV2", id)
        .then(response => {
          this.internal = response.data;
          this.$store.commit("SET_LOADER", false);
          this.dialogProduct = true;
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    addDeliveryOrder() {
      if (this.deliveryOrder == null) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "DO belum dipilih",
        });
      } else {
        if (this.items.findIndex(x => x === this.deliveryOrder) > -1) {
          this.$store.dispatch("toast", {
            type: "error",
            message: "DO sudah dipakai pada form",
          });
        } else {
          this.items.push(this.deliveryOrder);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    filterObjectDo(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
};
</script>

<style></style>
